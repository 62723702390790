import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Divider, getIconButtonUtilityClass, IconButton, Link, MenuItem, Select, Stack, Switch, TextField, Tooltip, Typography, useTheme } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import React, { useEffect, useRef, useState } from 'react';

import DownloadIcon from "@mui/icons-material/Download";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { AudioSourceMeta, Format, parseVideoURL, VideoMeta, VideoSourceMeta, VideoURL } from '../logic/parseVideo';
import { stringifyNumber } from '../logic/numfmt';
import FormatCard, { NoneDownloadCard } from './DownloadCard';
import { download, getDownloadLink } from '../logic/download';

export default function DownloadPage({ prefill }: { prefill: string | null }) {
    const theme = useTheme();

    const [error, setError] = useState<string | null>(null);
    const [videoURL, setVideoURL] = useState<VideoURL | null>(null);

    const [isMetaLoading, setMetaLoading] = useState<boolean>(false);
    const [videoMeta, setVideoMeta] = useState<VideoMeta | null>(null);

    const [videoFormatFrom, setVideoFormatFrom] = useState<Format | "none" | null>("none");
    const [audioFormatFrom, setAudioFormatFrom] = useState<Format | "none" | null>(null);

    const [shouldDownloadSubs, setShouldDownloadSubs] = useState<boolean>(false);
    const [targetSubId, setTargetSubId] = useState<string>("");
    const [subFormat, setSubFormat] = useState<string>("srt");

    useEffect(() => {
        if (!videoMeta) return;

        const audioFormats = videoMeta.formats.filter((f: Format, i) => {
            if (f.id.startsWith('sb')) return;
            if (!f.audio) return;
            if (f.video) return;
            if (f.audio.samples < 40_000) return;
            if (f.audio.rate < 128.0) return;

            return f
        });
        audioFormats.sort((a, b) => b.audio!.rate - a.audio!.rate);
        setAudioFormatFrom(audioFormats[0]);
    }, [videoMeta]);

    useEffect(() => {
        // set the video url to the meta gained from prefill
        if (prefill != null) {
            urlChange(prefill);
        }
    }, []);    

    async function queryMeta(id: string) {
        const resp = await fetch(`https://u0000400-ytdlp-api.fly.dev/meta/${id}`, { mode: 'cors' });
        
        const meta: VideoMeta = await resp.json();

        meta.formats.reverse(); // make quality first
        
        return meta;
    }

    async function urlChange(url: string) {
        try {
            setError(null);

            const v = parseVideoURL(url);
            setVideoURL(v);
        } catch (err: any) {
            setError(err.toString());
            setVideoURL(null);
        }

        setVideoMeta(null);
        setVideoFormatFrom("none");
        setAudioFormatFrom(null);
    }

    async function onTextChange(e: any) {
        const url = e.target.value;
        await urlChange(url);
    }
    
    async function onQueryClick() {
        if (videoURL == null) return;

        setMetaLoading(true);
        try {
            const m = await queryMeta(videoURL.id)
            setVideoMeta(m);

            const sub_keys = Object.keys(m.subs);
            if (sub_keys.length > 0) {
                if (sub_keys.includes("en")) {
                    // try to set format to english if english exists
                    setTargetSubId("en");
                } else {
                    // otherwise try to set sub format to first available sub
                    setTargetSubId(sub_keys[0]);
                }
            }

        } catch (error) {
            setError("Unable to retrieve video metadata.")
        }
        setMetaLoading(false);
    }

    return <>
        <TextField 
            label="Video URL" 
            variant="filled"
            defaultValue={prefill}
            error={error != null}
            helperText={error}
            onChange={onTextChange}
            fullWidth
        />

        <LoadingButton 
            variant="contained" 
            // endIcon={<DownloadIcon /> } 
            fullWidth 
            sx={{ mt: 1 }}
            disabled={videoURL == null || videoMeta != null}
            onClick={onQueryClick}
            loading={isMetaLoading}
        >
            Query Metadata
        </LoadingButton>

        {
            videoMeta == null ? null : <Box mt={5}>
                <Card variant="outlined" sx={{
                    mb: 3
                }}>
                    <CardMedia
                        component="img"
                        height="100px"
                        image={videoMeta.thumbnail}
                        // sx={{
                        //     filter: 'blur(3px)'
                        // }}
                    />
                    <CardHeader
                        avatar={
                            <Link color="inherit" href={`https://youtube.com/user/${videoMeta.author.name}`} underline="none">
                                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                                    {videoMeta.author.name[0].toUpperCase()}
                                </Avatar>
                            </Link>
                        }
                        title={videoMeta.title}
                        subheader={<>
                            <Link color="inherit" href={`https://youtube.com/user/${videoMeta.author.name}`} underline="hover">
                                <strong>
                                    {videoMeta.author.name}
                                </strong>
                            </Link>
                        </>}
                    />
                    <CardActions>
                        <Box p={1} display="flex">
                            <VisibilityIcon sx={{mr: 1, color: theme.palette.text.secondary}} />
                            <Tooltip arrow title={videoMeta.views.toLocaleString() + " views"}>
                                <Typography>
                                    {stringifyNumber(videoMeta.views)}
                                </Typography>
                            </Tooltip>
                        </Box>
                    </CardActions>
                </Card>

                {
                    audioFormatFrom == null ? null
                    : <>
                        <LoadingButton 
                            variant="contained" 
                            endIcon={<DownloadIcon /> } 
                            disabled={ videoFormatFrom == "none" && audioFormatFrom == "none" }
                            fullWidth 
                            sx={{ mt: 3 }}
                            loading={isMetaLoading}
                            size="large"
                            onClick={
                                () => {
                                    download(
                                        videoURL!.id, 
                                        "none", audioFormatFrom,
                                        shouldDownloadSubs,
                                        targetSubId, subFormat
                                    );
                                }
                            }
                        >
                            Download Selected
                        </LoadingButton>
                    </>
                }
            </Box>
        }
    </>;
}
